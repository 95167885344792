

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
     <div className="central">
      <h1>404 - The requested page could not be found</h1>
     </div>
    </div>
  );
}

export default NotFoundPage;
